<template>
  <div id="about">
    <div class="h-banner">
      <div class="h-title" :style="{fontSize: $i18n.locale === 'zh-cn' ? '66px' : '72px', lineHeight: $i18n.locale === 'zh-cn' ? '70px' : '65px'}">
        {{$t('About.Banner')}}
      </div>
    </div>

    <div class="p2">
      <img src="../../assets/img/about1.png"/>
        <div class="introduce">
        <div class="title">{{$t('About.Row_1._1')}}</div>
        <div class="text">{{$t('About.Row_1._2')}}</div>
        <div class="text">{{$t('About.Row_1._3')}}</div>
        <div class="text">{{$t('About.Row_1._4')}}</div>
        <div class="text">{{$t('About.Row_1._5')}}</div>
      </div>
    </div>

    <div class="p3">
      <div class="p3-box">
        <div class="title">Our Mission</div>
        <div class="list">
          <div class="item">
            <img src="../../assets/img/aboutp31.png"/>
            <div class="text">Driving Adoption of Roxe Chain</div>
          </div>

          <div class="item">
            <img src="../../assets/img/aboutp32.png"/>
            <div class="text">Growing our Global Community</div>
          </div>

          <div class="item">
            <img src="../../assets/img/aboutp33.png"/>
            <div class="text">Facitiliating Partnerships & Integrations</div>
          </div>

          <div class="item">
            <img src="../../assets/img/aboutp34.png"/>
            <div class="text">Onboarding & Educating Developers</div>
          </div>
        </div>
      </div>
    </div>

    <div class="p7">
      <div class="title">
        {{$t('Join')}}
      </div>
      <div class="link">
        <a href="https://twitter.com/RoxeChain" target="_blank"><img src="../../components/img/twitter.svg"/></a>
        <!-- <img src="../../components/img/facebook.svg"/> -->
        <a href="https://t.me/roxechainglobal" target="_blank"><img src="../../components/img/telegram.svg"/></a>
        <a href="https://medium.com/roxechain" target="_blank"><img src="../../components/img/m.svg"/></a>
        <a href="https://discord.gg/d3mDxd6th7" target="_blank"><img src="../../components/img/Union.svg"/></a>
      </div>
    </div>

     
    <!-- <roxe-footer></roxe-footer> -->
  </div>
</template>

<script>
// import RoxeFooter from "../../components/footer.vue";
export default {
  name: "about",
  components: {
    // RoxeFooter
  },
  methods: {},
};
</script>
<style lang="less" scoped> 
#about {
  .h-banner {
    height: 386px;
    background: linear-gradient(180deg, #085da3 0%, #3a89ca 100%);
    overflow: hidden;
    color: #ffffff;
    position: relative;
    .h-title {
      font-family: ParmaPetit;
      font-size: 80px;
      line-height: 100.3%;
      text-align: center;
      margin: 160px 0px 20px;
    }
    .h-title1 {
      font-family: Louis George Cafe;
      font-size: 26px;
      line-height: 20px;
      text-align: center;
    }
  }
  
  .p2 {
    width: 1110px;
    margin: 0 auto;
    padding: 84px 0px 76px 0px;
    display: flex;
    justify-content: space-between;
    img {
      width: 636px;
      // height: 517px;
      display: block;
    }
    .introduce {
      width: 439px;
      // margin-top: 63px;
      .title {
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 100.3%;
        color: #085DA3;
        margin-bottom: 20px;
      }
      .text {
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 19px;
        color: #818E9E;
        margin-bottom: 20px;
      }
    }
  }

  .p3 {
    background: #F6F8FB;
    padding-bottom: 60px;
    .p3-box {
      width: 1110px;
      margin: 0 auto;
      .title {
        text-align: center;
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 35px;
        color: #085DA3;
        padding-top: 45px;
        margin-bottom: 54px;
      }
      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          max-width: 255px;
          height: 228px;
          padding: 30px 28px 44px;
          background: #FFFFFF;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
          img {
            width: 50px;
            height: 50px;
            margin: 0 auto;
            margin-bottom: 26px;
            display: block;
          }
          .text {
            font-family: Louis George Cafe;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            color: #085DA3;
          }
        }
      }
    }
  }

  .p7 {
    .title {
      font-family: ParmaPetit;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 35px;
      text-align: center;
      color: #085DA3;
      margin-top: 74px;   
      margin-bottom: 53px;     
    }
    .link {
      display: flex;
      justify-content: center;
      margin-bottom: 110px;
      a {
        margin-right: 30px;
        &:last-child {
          margin-right: 0px;
        }
        img {
          display: inline-block;
          width: 30px;
        }
        &:hover {
          img {
            filter: contrast(0.5);
          }
        }
      }
    }
    
  }
}
@media screen and (min-width: 320px) and (max-width: 1210px) {
 #about .h-banner {
    .h-title {
      font-size: 40px!important;
      line-height: 40px!important;
    }
  }
  #about .p2 {
    padding-left: 50px;
    padding-right: 50px;
    flex-wrap: wrap;
    width: auto;
    justify-content: center;
    .introduce {
      width: auto;
    }
    img {
      width: 100%;
      display: block;
      height: auto;
      margin-bottom: 30px;
      // height: auto;
    }
  }
  #about .p3 {
    .p3-box {
      padding-left: 50px;
      padding-right: 50px;
      width: auto;
      .list {
        .item {
          width: 100%;
          max-width: none;
          height: auto;
          margin-bottom: 30px;
        }
      }
    }
  }
  
}

</style>
